import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    alert: false,
    alert_msg: "",
    error: false,
    error_msg: "",
  },
  getters: {
  },
  mutations: {
    alertThrow(state, msg) {
      state.alert_msg = msg;
      state.alert = true;
    },
    alertClear(state) {
      state.alert_msg = "";
      state.alert = false;
    },
    errorThrow(state, msg) {
      state.error_msg = msg;
      state.error = true;
    },
    errorClear(state) {
      state.alert_msg = "";
      state.error = false;
    },
  },
  actions: {
  },
  modules: {
  }
})
