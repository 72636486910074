<template>
  <div>
    <!-- <v-btn @click="kakaoLoginHandle">카카오 로그인</v-btn> -->
    <!-- <v-btn @click="NaverLoginHandle">네이버 로그인</v-btn>
    <v-btn @click="GoogleLoginHandle">구글 로그인</v-btn>
    <v-btn @click="AppleLoginHandle">애플 로그인</v-btn> -->

    <div>
      <v-btn @click="handleSocket" class="ma-2">1. 소켓 연결</v-btn>
      <v-btn @click="handleQrScan" class="ma-2">2. QR 스캔</v-btn>
    </div>

    <div ref="logs">
      
    </div>

  </div>
</template>

<script>
  import io from 'socket.io-client'; 
  import axios from 'axios';

  export default {
  name: 'Home',
  data() {
    return {
      socket_id: null,
      qr_code: "2c63b06aeb2448b0b8a278ce288ec2ba",
      reword_type: "CASH",
      token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYl9ubyI6MTIsIm1iX2lkIjoiaHl1bmdzZW9rOUBuYXZlci5jb20iLCJtYl9uYW1lIjoi7LWc7ZiV7ISdIiwibWJfcm9sZSI6InVzZXIiLCJpYXQiOjE3MjgwMjE0NTEsImV4cCI6MTczMDYxMzQ1MX0.4eCtLoPhvOicekQT1lI0LXuNlrpRDnWFzw9ncaJmUKY',
      idx: 0,
    }
  },
  methods: {
    test() {
      // 1728107597000 해당 값은 토큰 만료시간임
      const timestamp = 1730613451000;
      const date = new Date(timestamp);
      const formattedDate = date.toLocaleString(); // 또는 date.toISOString() 등 다른 형식 사용 가능
      console.log(formattedDate); // 변환된 날짜 출력
    },
    kakaoLoginHandle() {
      window.open('http://gasdev.greenloop.co.kr/auth/login/kakao', '_blank');
    },
    NaverLoginHandle() {
      window.open('http://localhost:3001/auth/login/naver', '_blank');
    },
    GoogleLoginHandle() {
      window.open('http://localhost:3001/auth/login/google', '_blank');
    },
    AppleLoginHandle() {
      window.open('http://localhost:3001/auth/login/apple', '_blank');
    },
    handleSocket() {
      const url = 'https://oas.greenloop.co.kr'; // 서버 주소
      const username = 'greenloop';
      const password = 'web123';
      const credentials = btoa(`${username}:${password}`); // Base64 인코딩
      const socket = io(url, {
        path: '/ottery/websocket',
        extraHeaders: {
          Authorization: `Basic ${credentials}` // Authorization 헤더에 Basic Auth 정보 추가
        }
      });
      socket.on('connect', () => {
        console.log('소켓 연결 성공:', socket.id);
        
        this.socket_id = socket.id;
        const data = {
          socket_id: socket.id,
          message: '소켓 연결 성공'
        };
        this.$refs.logs.innerHTML += `<div>${JSON.stringify(data)}</div>`;
      });
      socket.on('connect_error', (error) => {
        console.error('소켓 연결 실패:', error);
      });

      //메세지 받기
      socket.on('message', (data) => {
        this.idx = data.context.idx;
        this.$refs.logs.innerHTML += `<div>${JSON.stringify(data)}</div>`;
      });
    },
    async handleQrScan() {
      const url = "https://gasdev.greenloop.co.kr/cp/ottery/qr/scan";
      // const url = "http://localhost:14001/cp/ottery/qr/scan";
      const params = {
        qr_code: this.qr_code,
        socket_id: this.socket_id,
        reword_type: this.reword_type,
      };

      await axios.post(url, params, {
          headers: {
            Authorization: `Bearer ${this.token}`
          }
        })
        .then((res) => {
          console.log('QR 스캔 결과:', res.data);
        })
        .catch((error) => {
          console.error('QR 스캔 실패:', error);
        });
    },
    async handleSticker() {
      const url = "https://oas.greenloop.co.kr/v1/ottery/status/sticker";
      const params = {
        idx: this.idx,
        sticker: "completed"
      };

      const username = 'greenloop';
      const password = 'web.!123';
      const credentials = btoa(`${username}:${password}`); // Base64 인코딩

      const response = await axios.post(url, params, {
        headers: {
          Authorization: `Basic ${credentials}`
        }
      });

      console.log('스티커 발급완료:', response);
      
    }
  },
}
</script>
