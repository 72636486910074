<template>
  <v-app>
   

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar v-model="alert_snackbar" top centered :timeout="3000">
      {{ alert_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="alert_snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="error_snackbar"
      centered
      color="error"
      top
      :timeout="3000"
    >
      {{ error_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="error_snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      alert_snackbar: false,
      error_snackbar: false,
    };
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    },
    error() {
      return this.$store.state.error;
    },
    alert_msg() {
      return this.$store.state.alert_msg;
    },
    error_msg() {
      return this.$store.state.error_msg;
    },
  },
  watch: {
    alert(newValue) {
      if (newValue) {
        this.alert_snackbar = true;
      }
    },
    alert_snackbar(newValue) {
      if (!newValue) {
        this.$store.commit("alertClear");
      }
    },
    error(newValue) {
      if (newValue) {
        this.error_snackbar = true;
      }
    },
    error_snackbar(newValue) {
      if (!newValue) {
        this.$store.commit("errorClear");
      }
    },
  },
};
</script>
